<template>
  <v-dialog
    v-model="isOpening"
    persistent
    :fullscreen="isMobile"
    width="80%"
    max-width="512"
  >
    <v-card class="confirm-dialog__content">
      <v-row class="">
        <v-col cols="12" class="text-left">
          <h1 v-html="title"></h1>
          <p class="text-left" v-html="text"></p>
        </v-col>
      </v-row>

      <v-row class="confirm-dialog__buttons">
        <v-col v-if="showCancelButton" cols="6" class="text-left">
          <v-btn
            class="pkmn-button"
            outlined
            :color="warning ? 'danger' : 'primary'"
            @click="close"
          >
            {{ $t('form_cancel') }}
          </v-btn>
        </v-col>
        <v-col :cols="showCancelButton ? 6 : 12" class="text-right">
          <v-btn
            class="pkmn-button pkmn-button--confirm"
            :color="warning ? 'danger' : 'primary'"
            @click="confirm"
          >
            {{ $t('g_confirm') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmDialog',
  props: {
    title: { type: String, default: '' },
    text: { type: String, default: '' },
    warning: { type: Boolean, default: false },
    showCancelButton: { type: Boolean, default: true }
  },
  data() {
    return {
      isOpening: false,
      mobileBreakpoint: 767
    }
  },
  computed: {
    // @todo: move this to helper
    isMobile() {
      return window.innerWidth <= this.mobileBreakpoint
    }
  },
  methods: {
    close() {
      this.isOpening = false
      this.$emit('close')
    },
    confirm() {
      this.$emit('confirm')
      this.close()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/common';

.confirm-dialog__content {
  padding: 48px 80px !important;
  text-align: left;

  @media #{map-get($display-breakpoints, 'xs-only')} {
    padding: 40px 16px !important;
  }
}

.pkmn-button--confirm {
  color: #fff;
}

.confirm-dialog__buttons .pkmn-button {
  width: 100%;
}
</style>
