<template>
  <v-dialog
    v-model="alwaysOpen"
    :fullscreen="isMobile"
    width="80%"
    max-width="512px"
    @click:outside="closeForm"
  >
    <v-card class="form-dialog form-dialog--group">
      <h1 class="form-dialog__title">{{ dialogTitle }}</h1>

      <v-form ref="form">
        <v-row dense>
          <v-col cols="12">
            <v-text-field
              v-model="formData.name"
              class="form__name pkmn-input"
              :label="$t('group_name')"
              validate-on-blur
              :rules="[rules.required]"
            >
            </v-text-field>
          </v-col>
        </v-row>

        <form-billing-info
          ref="formBillingInfo"
          :data="formData.payment_methods"
          :groupAccountId="formData.account_id"
          :orderRef="formData.order_ref"
          @submit="paymentInfoUpdated = true"
          @error="handlePaymentMethodError"
        />

        <v-row class="form__buttons" v-if="formSubmitted">
          <v-col cols="12" class="text-center">
            <v-progress-circular color="#37bd00" indeterminate size="24" />
            <span class="wait-text">{{ $t('please_wait') }}...</span>
          </v-col>
        </v-row>

        <v-row class="form__buttons" v-else>
          <v-col cols="6" class="text-left">
            <v-btn
              class="pkmn-button"
              color="primary"
              outlined
              @click="closeForm"
            >
              {{ $t('form_cancel') }}
            </v-btn>
          </v-col>
          <v-col cols="6" class="text-right">
            <v-btn
              class="pkmn-button pkmn-button--has-border"
              color="primary"
              :disabled="!$store.state.isPageReady"
              @click="submit"
            >
              <span v-if="$store.state.isPageReady">
                {{ $t(formType === 'add' ? 'button_add' : 'form_save') }}
              </span>
              <span v-else>
                <v-progress-circular indeterminate size="16" />
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
/**
 * WARNING: this file is extremely hard to follow, and need to be refactored
 * Reason: need to call multiple endpoint to update one form
 * Come back to this when graphql is available
 */
import api from '@/helpers/api'
import companyHelpers from '@/helpers/company'
import rules from '@/helpers/validation'

import FormBillingInfo from '@/components/Settings/FormBillingInfo'

export default {
  name: 'FormGroup',
  components: { FormBillingInfo },
  props: ['data'],
  data() {
    return {
      formType: 'add', // form type - add or edit - determined when form first opened
      alwaysOpen: true, // set this to always open. Show / hide the form by using v-if in the parent component
      formData: {},
      defaultFormData: {
        name: '',
        account_id: null
      },
      rules: rules,
      mobileBreakpoint: 767,
      formSubmitted: false,
      isNameChanged: false, // if name did not changed, skip calling group info edit. Reason: have to call multiple endpoints to edit group.  @todo: this will be revisited after graphql implemented
      paymentInfoUpdated: true,
      formDataUpdated: true
    }
  },
  watch: {
    paymentInfoUpdated(state) {
      if (state) {
        if (
          this.formDataUpdated &&
          this.$refs.formBillingInfo.formData.current_payment_method === 'card'
        ) {
          this.processComplete()
        } else {
          this.submitPaymentInfoForm()
        }
      }
    },
    formDataUpdated(state) {
      if (state && this.paymentInfoUpdated) {
        this.processComplete()
      }
    }
  },
  computed: {
    isMobile() {
      return window.innerWidth <= this.mobileBreakpoint
    },
    dialogTitle() {
      return this.formType === 'edit'
        ? `${this.$t('group_edit_title')} "${this.formData.name}"`
        : this.$t('group_add_title')
    }
  },
  methods: {
    closeForm() {
      this.$emit('close')
    },

    submit() {
      // prevent submit on basic info missing
      if (!this.$refs.form.validate()) {
        return
      }

      // prevent submit on payment info missing
      if (!this.$refs.formBillingInfo.$refs.form.validate()) {
        return
      }

      // prevent user reclicking the button
      switch (this.formType) {
        case 'add':
          this.submitAdd()
          break
        case 'edit':
          this.submitEdit()
          break
        default:
          break
      }
    },

    /**
     * Call backend to add new group
     */
    async submitAdd() {
      if (
        this.$refs.formBillingInfo.formData.current_payment_method === 'card' &&
        !this.$refs.formBillingInfo.validateBraintreeForm()
      ) {
        return
      }
      // @todo: track data
      // create the group associated to the company
      const apiSettings = {
        method: 'post',
        service: 'users',
        url: `v1/companies/${this.$store.state.companyData.id}/groups`,
        data: {
          name: this.formData.name
        }
      }
      this.paymentInfoUpdated = false
      this.formDataUpdated = false
      this.formSubmitted = true

      try {
        const response = await api.promise(apiSettings)
        this.formDataUpdated = true
        // on group creation success, add order reference and payment method
        // pass the newly created group account_id to billing form
        this.formData.account_id = response.groups.account_id
        this.formData.id = response.groups.id
        this.formType = 'edit'
        // vue does not detect this change, force update it
        this.$forceUpdate()
        // trigger billing form submission
        this.$nextTick(() => {
          this.submitPaymentInfoForm()
        })
      } catch (error) {
        this.$store.dispatch('setSystemMessage', error)
        // re-enable the buttons on error
        this.formSubmitted = false
      }
    },

    async submitEdit() {
      const paymentMethod =
        this.$refs.formBillingInfo.formData.current_payment_method
      if (paymentMethod === 'card') {
        const cardFields =
          this.$refs.formBillingInfo?.braintree?.instances?.hostedFields?._state
            ?.fields
        const cardFieldsIsNotEmpty = Object.entries(cardFields).find(
          ([key, value]) => {
            return !value.isEmpty
          }
        )
        if (cardFieldsIsNotEmpty) {
          this.formSubmitted = true
          this.paymentInfoUpdated = false
          this.submitPaymentInfoForm()
        }
      } else if (paymentMethod && paymentMethod !== 'card') {
        this.formSubmitted = true
        this.submitPaymentInfoForm()
      }
      // skip calling group edit endpoint if name does not change to reduce the waiting time
      // reason: have to call multiple endpoints for updating the form
      // @todo: come back when graphql implemented
      var originalName = this.data.account_name
      var newName = this.formData.name
      var isNameChanged = originalName !== newName

      if (!isNameChanged) {
        return
      }

      // @todo: track data
      const apiSettings = {
        method: 'put',
        service: 'users',
        url: `v1/groups/${this.formData.id}`,
        data: {
          name: this.formData.name
        }
      }
      this.formDataUpdated = false
      this.formSubmitted = true

      try {
        await api.promise(apiSettings)
        this.formDataUpdated = true
      } catch (error) {
        this.$store.dispatch('setSystemMessage', error)
        // re-enable the buttons on error
        this.formSubmitted = false
      }
    },
    /**
     * Submit the payment info related part, including order ref and card / invoice
     */
    submitPaymentInfoForm() {
      // this form require a group account id. this is retrieved via this.formData.account_id
      this.$refs.formBillingInfo.submitForm()
    },
    /**
     * Adding payment is the last step, so this step completes mean the whole process complete
     * @todo: This is bad practice. Need to revisit with async/await, or graphql, or both
     */
    async processComplete() {
      // re-fetch the group list again to reflect the change in data table
      await companyHelpers.fetchCompanyInfo(this.$store.state.companyData.id)
      // emit the event to parent component, to show success message
      var event = this.formType === 'edit' ? 'submitEdit' : 'submitAdd'
      this.$emit(event, this.formData)
    },
    handlePaymentMethodError() {
      this.formSubmitted = false
      this.$store.dispatch('setPageReadyStatus', true)
    }
  },
  mounted() {
    // define if this form is edit or add
    this.formType = this.data.account_id ? 'edit' : 'add'
    this.formData = Object.assign({}, this.data || this.defaultFormData)
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/common';

.form-dialog {
  padding: 48px 80px;
  text-align: left;

  @media #{map-get($display-breakpoints, 'xs-only')} {
    padding: 40px 16px !important;
  }
}

.form-dialog__title {
  margin-bottom: 32px;
}

.form__buttons {
  margin-top: 16px;
}

.form__buttons .pkmn-button {
  width: 100%;
}

.wait-text {
  color: $color-brand;
  padding-left: 12px;
  font-weight: 600;
}
</style>
