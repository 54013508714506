<template>
  <div class="groups__data">
    <v-data-table
      class="data__table"
      ref="table"
      :headers="headers"
      :header-props="{ sortByText: $t('transactions_sort_by') }"
      :items="data"
      fixed-header
      hide-default-footer
      disable-pagination
      must-sort
      sort-by="account_name"
      :mobile-breakpoint="mobileBreakpoint"
    >
      <template v-slot:item.active_payment_method="{ item }">
        <span v-if="item.active_payment_method">
          <span>{{
            $t(`settings_billing_${item.active_payment_method}`)
          }}</span>
          <span v-if="item.card_masked_pan">
            {{ item.card_masked_pan }}
          </span>
        </span>
      </template>
      <template v-slot:item.account_id="{ item }">
        <div class="mobile-row__action">
          <img
            class="data__action-trigger"
            src="@/assets/button-list-menu.svg"
          />

          <div>
            <v-btn
              v-if="auth.checkPermission('edit-group')"
              class="pkmn-button pkmn-button--no-padding data__action-button"
              text
              color="primary"
              @click="editGroup(item)"
            >
              <img src="@/assets/button-edit.svg" />
            </v-btn>
            <v-btn
              v-if="auth.checkPermission('delete-group') && !isOnlyOneGroup"
              class="pkmn-button pkmn-button--no-padding data__action-button"
              text
              color="warning"
              @click="deleteGroup(item)"
            >
              <img src="@/assets/button-delete.svg" />
            </v-btn>
          </div>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import auth from '@/auth'

export default {
  name: 'GroupsDataTable',
  props: ['data'],
  data() {
    return {
      auth: auth,
      headers: [
        { text: this.$t('group_name'), value: 'name' },
        { text: this.$t('group_order_ref'), value: 'order_ref' },
        {
          text: this.$t('settings_billing_payment_method'),
          value: 'active_payment_method'
        },
        { text: '', value: 'account_id', align: 'right', sortable: false }
      ],
      mobileBreakpoint: 767 // screen width in pixel to switch to mobile view
    }
  },
  computed: {
    isMobile() {
      return window.innerWidth <= this.mobileBreakpoint
    },
    isOnlyOneGroup() {
      // prevent the user to delete group if it is the last one
      return this.data && this.data.length === 1
    }
  },
  methods: {
    editGroup(selectedRecord) {
      this.$emit('edit', selectedRecord)
    },
    deleteGroup(selectedRecord) {
      this.$emit('delete', selectedRecord)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/style/common';
</style>
